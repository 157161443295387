define('frontend/models/care/measurement-plan', ['exports', 'frontend/models/application', 'ember-data', 'lodash', 'ember-cp-validations', 'frontend/mixins/validation-helper'], function (exports, _application, _emberData, _lodash, _emberCpValidations, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true),
    measurementPlanScales: {
      validators: [(0, _emberCpValidations.validator)('length', {
        min: 1
      }), (0, _emberCpValidations.validator)('hasMany')]
    }
  });

  // TODO: use attributes method when ember is upgraded
  var copyableFields = ['name', 'defaultPlanType', 'description'];
  var copyableScaleFields = ['scale', 'patientSessionType', 'onsetSession', 'onsetTime', 'repeatSessions', 'maxRepeatInterval', 'discontinueSession', 'discontinueTime', 'alternationGroup', 'alternationPriority', 'measureOnsetFrom', 'oncePerPeriod', 'rolloutBefore', 'rolloutPeriod'];

  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    global: _emberData.default.attr('boolean'),
    defaultPlanType: _emberData.default.attr('string'),
    shared: _emberData.default.attr('boolean'),
    groupId: _emberData.default.attr('string'), // Technically a uuid. Represents a grouping of measurement plans so that if you minorly
    // alter the plan you don't reset your session number progress.
    autogenerated: _emberData.default.attr('boolean', { defaultValue: false }),

    useAutomaticName: Ember.computed('name', function () {
      return !!this.get('name');
    }),

    automaticName: Ember.computed('measurementPlanScales.@each.scale', function () {
      var scaleNames = this.get("measurementPlanScales").mapBy('scale.translatedShortnameText');

      if (scaleNames && scaleNames.length > 0) {
        return scaleNames.join(', ');
      } else {
        return 'Empty Plan';
      }
    }),

    nameWithAutomaticFallback: Ember.computed('name', 'automaticName', function () {
      var name = this.get("name");

      if (name) {
        return name;
      }

      return this.get("automaticName");
    }),

    isEditable: Ember.computed.not("global"), // For now, allow every user created scale to be editable

    author: _emberData.default.belongsTo('user'),

    measurementPlanScales: _emberData.default.hasMany('care/measurement-plan-scale'),

    alternatingScaleGroups: Ember.computed('measurementPlanScales.@each.isDeleted', function () {
      var alternating = this.get('measurementPlanScales').filterBy('alternationGroup').rejectBy('isDeleted', true);

      return _lodash.default.groupBy(alternating.toArray(), function (mps) {
        return mps.get('alternationGroup');
      });
    }),

    individualScales: Ember.computed('measurementPlanScales.@each.isDeleted', function () {
      return this.get("measurementPlanScales").rejectBy('alternationGroup').rejectBy('isDeleted', true);
    }),

    duplicate: function duplicate(user) {
      var _this = this;

      var newProperties = this.getProperties.apply(this, copyableFields);
      newProperties.author = user;
      newProperties.institute = user.get('institute');
      newProperties.isClone = true;
      newProperties.name = 'Copy of ' + newProperties.name;
      var newRecord = this.get('store').createRecord('care/measurementPlan', newProperties);
      newRecord.get('measurementPlanScales').addObjects(this.get('measurementPlanScales').map(function (mps) {
        var fields = Ember.merge(mps.getProperties(copyableScaleFields), { measurementPlan: newRecord, isClone: true });
        return _this.get('store').createRecord('care/measurementPlanScale', fields);
      }));

      return newRecord;
    }
  });
});