define('frontend/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-data-has-many-query'], function (exports, _emberData, _dataAdapterMixin, _emberDataHasManyQuery) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.setAuthenticationHeaders = undefined;


  var setAuthenticationHeaders = function setAuthenticationHeaders(xhr, data) {
    var identificationAttributeName = "id";
    var userToken = data['authentication_token'];
    var userType = data['user_type'];
    var userIdentification = data[identificationAttributeName];

    if (!isEmpty(userToken) && !isEmpty(userIdentification)) {
      var authData = 'authentication_token="' + userToken + '", user_type="' + userType + '", id="' + userIdentification + '"';
      xhr.setRequestHeader('Authorization', 'Token ' + authData);
    }
  };

  var setImpersonationHeaders = function setImpersonationHeaders(xhr, impersonatingUserId) {
    if (!impersonatingUserId) {
      return;
    }

    xhr.setRequestHeader('Impersonating-User-Id', impersonatingUserId);
  };

  // We always set this header in Ember - only the React app supports selecting institutes.
  var setInstituteGroupHeaders = function setInstituteGroupHeaders(xhr) {
    xhr.setRequestHeader('Active-Institute-Ids', 'all');
  };

  exports.setAuthenticationHeaders = setAuthenticationHeaders;
  var isEmpty = Ember.isEmpty;
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, _emberDataHasManyQuery.default.RESTAdapterMixin, {
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionUser: Ember.inject.service(),
    namespace: 'api',

    authorize: function authorize(xhr) {
      var data = this.get('session.data.authenticated');
      setAuthenticationHeaders(xhr, data);
      setImpersonationHeaders(xhr, this.get("sessionUser.impersonatingUserId"));
      setInstituteGroupHeaders(xhr);
    },


    // We need to add 498 and 499 to the status responses to allow for token errors.
    handleResponse: function handleResponse(status, request, body, params) {
      if (status === 401 || status === 498 || status === 499) {
        // If this is a page refresh, then the metrics context may not have been correctly set.
        this._ensureMetricsContext();
        if (this.get('session.isAuthenticated')) {
          console.log("Received invalidation from server, logging out");
          this.set("session.logoutReason", "timeout");
          this.get("metrics").trackEvent({ event: "user.logout", reason: "invalidToken", status: status, serverUrl: params.url });
          this.get('sessionUser').logout();
        }
        return body;
      }

      var result = this._super.apply(this, arguments);
      if (status === 400 || status >= 500) {
        Raven.captureException(result);
      }

      return result;
    },


    findBelongsTo: function findBelongsTo(store, snapshot, url, relationship) {
      return this._super(store, snapshot, this.fixProxyUrl(url), relationship);
    },

    findHasMany: function findHasMany(store, snapshot, url, relationship) {
      //hack to get ember proxy to work in front-end decoupled mode
      url = this.fixProxyUrl(url);

      if (relationship.options.fetchAll) {
        var paramChar = url.indexOf('?') >= 0 ? '&' : '?';
        url = url + (paramChar + 'page%5Blimit%5D=300');
      }

      return this._super(store, snapshot, url, relationship);
    },

    fixProxyUrl: function fixProxyUrl(url) {
      if ((window.location.port === 4200 || window.location.port === "4200") && window.location.hostname === 'localhost') {
        url = url.replace('https://demo.mirah.com', 'http://localhost:4200');
        url = url.replace('https://test.qa.mirah.com', 'http://localhost:4200');
        url = url.replace('http://localhost:3000', 'http://localhost:4200');
      }
      return url;
    },

    _ensureMetricsContext: function _ensureMetricsContext() {
      this.set('metrics.context.userId', this.get("session.data.authenticated.id"));

      var activeRoleType = this.get("session.data.authenticated.active_role_type");
      var activeRoleId = this.get("session.data.authenticated.active_role_id");

      switch (activeRoleType) {
        case "provider":
          this.set("metrics.context.providerId", activeRoleId);
          break;
        case "patient":
          this.set("metrics.context.patientId", activeRoleId);
          break;
        case "related_person":
          this.set("metrics.context.relatedPersonId", activeRoleId);
          break;
      }
    },


    // The default implementation does not support include queries for find records. this patches it so that
    // you can still specify an include or query params.
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var url = this._buildURL(modelName, id);
      if (snapshot.include || snapshot.adapterOptions) {
        var customQuery = snapshot.adapterOptions ? snapshot.adapterOptions : {};
        var includes = snapshot.include ? { include: snapshot.include } : {};
        url = url + "?" + $.param($.extend({}, customQuery, includes));
      }
      return url;
    },

    urlForUpdateRecord: function urlForUpdateRecord(store, type, snapshot) {
      var url = this._super(store, type, snapshot);
      if (snapshot.adapterOptions) {
        url = url + "?" + $.param(snapshot.adapterOptions);
      }
      return url;
    }
  });
});