define('frontend/pods/components/common/controls/primary-doctor-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'provider-select',
    excludeNonpracticing: true,
    preload: Ember.inject.service(),

    shownProviders: Ember.computed('preload.providers.[]', 'excludeNonpracticing', function () {
      var providers = this.get("preload.providers");

      if (this.get("excludeNonpracticing")) {
        return providers.filterBy('practicing', true);
      } else {
        return providers;
      }
    }),

    cid: "provider",
    property: "provider",
    labelKey: 'kiosk.patient.createPatient.primaryClinician',

    actions: {
      providerSelected: function providerSelected(provider) {
        Ember.set(this.get('model'), this.get('property'), provider);
      }
    }
  });
});