define('frontend/pods/components/reporting/columns/provider-role/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var roleMap = {
    psychiatrist: 'fa-medkit',
    psychologist: null,
    therapist: null,
    social_worker: null,
    research_assistant: 'fa-clipboard',
    administrator: 'fa-clipboard',
    director: 'fa-clipboard',
    supervisor: 'fa-clipboard',
    clinician: null
  };

  exports.default = Ember.Component.extend({
    tagName: 'span',
    roleIcon: Ember.computed("value", function () {
      var role = this.get("value");

      if (role) {
        return roleMap[role];
      }
    })
  });
});