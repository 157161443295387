define("frontend/locales/en/executive", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    navTitle: "Executive",
    login: {
      navTitle: "Login",
      mode: "Admin",
      welcome: "",
      pleaseSignIn: "Welcome, please login to your account.",
      login: "Log In",
      autoLogout: "Your session has expired after a period of inactivity. Please sign in again.",
      forgottenPassword: "Forgot Password?"
    },
    dashboard: {
      navTitle: "Dashboard",
      title: "Executive Dashboard",
      description: "Organization analytics",
      timeOptions: {
        custom: 'Custom Date Range',
        today: 'Today',
        yesterday: 'Yesterday',
        thisWeek: "This week",
        thisMonth: 'This month',
        thisYear: 'This year',
        lastDay: 'Past day',
        lastWeek: 'Past week',
        lastMonth: 'Past month',
        lastYear: 'Past year',
        allTime: 'All Time'
      },
      column: {
        numUnviewedAssessments: 'Unviewed assessments',
        numAssessmentsViewed: 'Assessments viewed',
        numClients: 'Patient',
        lastLogin: 'Last login',
        active: 'Active',
        numAssessments: 'Completed Assessments',
        lastAssessment: 'Last Assessment'
      },
      measure: {
        visitsCompletedMeasure: {
          title: "Visits where the patient completed a measure",
          description: "Percentage of visits where the patient completed an assessment",
          columnHead: 'Clinician'
        },
        assessedOnePatient: {
          title: "Reports reviewed with patient",
          description: "Fraction",
          columnHead: 'Clinician'
        },
        reportsViewedPromptly: {
          title: "Reports viewed prior to session",
          description: "Percentage of reports viewed within 24 hours of their submission",
          columnHead: 'Clinician'
        },
        completedAnAssessment: {
          title: "Sent assessments completed",
          description: "Percentage of assessments sent to patients completed",
          columnHead: 'Patient'
        },
        measuredRecently: {
          title: "Regularly measured",
          description: "Percentage of patients who have been measured in every appointment",
          columnHead: 'Patient'
        },
        regularlyMeasured: {
          title: "Completed an assessment in past 2 weeks",
          description: "Percentage of current patients who have completed an assessment within the past two weeks",
          columnHead: 'Patient'
        },
        patientImproving: {
          title: "Patients improving on targeted symptoms",
          description: "Percentage of current patients showing significant improvement on targeted symptoms over the most recent interval"
        },
        patientStable: {
          title: "Patients with no worsening symptoms",
          description: "Percentage of current patients have not progressively and signficantly worsened over the most recent interval"
        }
      }
    },
    events: {
      navTitle: "Event logs",
      list: {
        title: "Recent events",
        description: "Event log"
      }
    },
    reports: {
      crumb: 'Analytics',
      navTitle: 'Analytics'
    }
  };
});