define("frontend/locales/en/patient", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    measure: "measure",
    welcome: {
      title: "Welcome!",
      description: "Here you can help us guide your therapy.",
      backToLanding: "Back to Landing Page",
      start: "Start",
      iAmAClient: "I am a patient",
      iAmARelatedPerson: "I am a parent/caregiver of a patient"
    },
    kiosk: {
      failed: "We have not been able to verify your details. Please try again, or if the problem persists, contact your clinician.",
      ambiguousLogin: "You could not be logged in due to conflicting patient records, please contact staff for a login code."
    },
    invitation: {
      title: "Welcome to Mirah",
      description: "Enter your info",
      submit: "Continue",
      failed: "We have not been able to verify your details. Please try again, or if the problem persists, contact your clinician.",
      exit: {
        redirect: "Thank you. Beginning the measure now..."
      },
      alreadyComplete: "This measurement has already been completed. Please close your browser window.",
      "return.complete": "Success! Thanks for your time. Please close the browser window.",
      "return.exit": "You have exited the survey. Please close your browser window.",
      "return.timeout": "You have been timed out due to a period of inactivity.",
      early: 'This survey is not available for completion yet. Please try again later.',
      expired: 'This survey has expired and is no longer accessible.',
      error: 'An error has occurred. Please try again later or contact support@mirah.com'
    },
    confirmDetails: {
      title: "Confirm your details",
      description: "Since this is your first time",
      confirm: "Yes, correct",
      needEdit: "No, these are incorrect",
      pleaseConfirm: "Please enter the correct details"
    },
    upToDate: {
      description: "You're up to date! You have no measurements to take. Good work!",
      logout: "Log Out"
    },
    forgottenPassword: {
      title: "Forgot Password?",
      description: "If you have an email address attached to your account and have forgotten your password, you may request a password reset. An email will be sent to your account containing password reset instructions.",
      noEmail: "If you do not have an email address and have forgotten your password, please contact a member of staff for assistance.",
      sendResetEmail: "Send Reset Email",
      emailSent: "If the username exists, an email has been sent to the email address associated with it.",
      backToLogin: "Back to Login"
    },
    login: {
      pleaseSignIn: "Please sign in",
      description: "Choose your login method",
      username: "Log in with my username",
      noUsername: "Log in without username",
      forgottenPassword: "Forgot Password?",
      otherMechanisms: "Other ways to log in",
      submit: "Log In",
      keycode: "Keycode login",
      code: "Code",
      email: 'Email Address'
    },
    logout: "You have been logged out."
  };
});