define('frontend/locales/en/related-person', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    measure: "measure",
    login: {
      participantFirstName: 'Your First Name',
      patientLastName: 'Patient\'s Last Name',
      patientDob: 'Patient\'s Date of Birth',
      patientLastSsn: 'Patient\'s Last 4 SSN Digits',
      email: "Email Address",
      submit: "Log In",
      description: "Choose login method",
      username: "I know my username and password",
      noUsername: "Login without username",
      pleaseSignIn: "Please sign in",
      forgottenPassword: "Forgot Password?",
      otherMechanisms: "Other ways to log in",
      yourDetails: "Your info",
      clientDetails: "Patient info"
    },
    assessment: {
      patientName: "Patient Name",
      date: "Date",
      takeAssessment: "Take Assessment",
      confirm: "You are about to answer questions relating to {patientName}",
      chooseClient: "Select the person you're completing questions about now.",
      confirmButton: "Yes, start",
      upToDate: "You're all up to date!",
      logout: "Log Out"
    }
  };
});