define("frontend/locales/en/common", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    id: "Internal Identifier",
    download: "Download",
    delete: "Delete",
    submit: 'Submit',
    save: 'Save',
    close: 'Close',
    username: "Username",
    emailAddress: "Email Address",
    password: "Password",
    mrn: "ID Number",
    lastSsn: "Last 4 SSN Digits",
    lastSsnPlaceholder: "XXXX",
    patient: "Patient",
    dateUpdated: "Date Updated",
    dateCreated: "Date Created",
    dateOfBirth: "Date of Birth",
    createdAt: "Created at",
    action: "Action",
    actions: "Actions",
    select: "select",
    assessment: "Assessment",
    doctor: "Doctor",
    provider: "Provider",
    you: "You",
    status: "Status",
    details: "Details",
    active: "Active",
    create: "Create",
    cancel: "Cancel",
    selected: "Currently Selected",
    customize: "Customize",
    showDetails: 'Show details',
    hideDetails: "Hide details",
    show: 'Show',
    hide: 'Hide',
    updated: 'updated',
    exit: "Exit",
    none: 'none',
    all: 'All',
    about: 'about',
    load: 'Load',
    loading: 'Loading...',
    termsOfUse: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
    for: 'for',
    choose: 'Choose',
    change: 'Change',
    name: 'Name',
    unknownUser: 'Unknown User',
    disabled: 'Disabled',
    configuration: 'Configuration',
    controls: {
      play: "Play",
      pause: "Pause",
      resume: "Resume",
      stop: "Stop"
    },
    edit: 'Edit',
    noHipaaWarning: 'This is a demo site -- do NOT use for real patient data',
    unknown: 'unknown',
    untilFurtherNotice: 'Until further notice',
    now: 'Now',
    false: 'False',
    true: 'True',
    landing: {
      patient: {
        description: "Patient portal for taking assessments",
        title: "Measure",
        cta: "I am a patient"
      },
      provider: {
        description: "Provider portal for treatment planning and patient assessment",
        title: "Track",
        cta: "I am a provider"
      },
      executive: {
        description: "Executive portal for managing best practices",
        title: "Analyze",
        cta: "I am an executive"
      }
    },
    errorOccurred: 'An error has occurred',
    yes: "Yes",
    no: "No",
    search: "Search",
    helpGlossary: "Help / Glossary",
    description: "Description",
    print: 'Print',
    inactive: {
      title: "You have been automatically logged out",
      description: "Another tab or window on this computer has logged in with different credentials. You have been automatically logged out."
    },
    applicationError: {
      title: "An error has occurred",
      advice: "Please make sure you are still connected to the internet.",
      contact: "If this persists, please contact <a href='mailto:support@mirah.com'>support@mirah.com</a>",
      escapeLink: "Mirah home"
    },
    notFound: {
      title: "Page not found",
      advice: "The page could not be found, or you may need to log in to see it. If you are trying to take an assessment, it may have expired.",
      contact: "Questions?  Contact <a href='mailto:support@mirah.com'>support@mirah.com</a>",
      escapeLink: "Mirah home"
    },
    invalid: {
      title: "Invalid Portal",
      advice: "The site you are requesting could not be found.",
      contact: "Questions?  Contact <a href='mailto:support@mirah.com'>support@mirah.com</a>"
    },
    asyncButton: {
      saving: "Working..."
    },
    expired: {
      title: "Logged Out",
      advice: "You have been logged out due to inactivity.",
      contact: "You will be redirected to the login page momentarily."
    },
    primaryDoctorSelect: {
      prompt: "Choose a provider"
    },
    notificationPreference: {
      label: 'Notification Preference',
      prompt: 'Default',
      both: 'Text and Email',
      email: 'Email Only',
      text: 'Text Only',
      none: 'None'
    },
    forms: {
      scheduling: {
        recurrence: 'Recurrence',
        until: 'until',
        time: {
          am: 'am',
          pm: 'pm',
          minutes: {
            '0': '00',
            '15': '15',
            '30': '30',
            '45': '45'
          }
        },
        period: {
          none: 'none',
          weekly: "Weekly",
          biweekly: "Every two weeks",
          monthly: "Monthly",
          quarterly: "Quarterly",
          biannually: "Every six months",
          annually: "Annually",
          daily: "Daily",
          weekdays: "Daily on weekdays"
        }
      },
      units: {
        H: "Hours",
        D: "Days",
        W: "Weeks",
        M: "Months",
        Y: "Years"
      }
    },
    navigation: {
      enablePrivacyMode: 'Enable discreet mode',
      disablePrivacyMode: 'Disable discreet mode',
      careUnits: 'Care Unit Manager'
    },
    modes: {
      track: {
        navTitle: 'Provider View',
        icon: 'fa-user-md'
      },
      admin: {
        navTitle: 'Executive View',
        icon: 'fa-area-chart'
      },
      operations: {
        navTitle: 'Operations View',
        icon: 'fa-plug'
      },
      internal: {
        navTitle: 'Mirah Internal',
        icon: 'fa-heart-o'
      },
      measure: {
        navTitle: 'Patient Kiosk',
        icon: 'fa-tablet'
      }
    },
    language: {
      en: 'English',
      'en-us': 'English (US)',
      'en-gb': 'English (GB)',
      es: 'Spanish'
    },
    codeLogin: {
      heading: 'Keycode login',
      description: "You can obtain a temporary keycode from your clinician or the front desk",
      code: '6-letter code',
      continue: 'Continue',
      back: 'back to home screen'
    },
    confirmIdentity: {
      title: "Please confirm your identity",
      description: "Are you {name}?",
      yes: "Yes",
      no: "No"
    },
    showAll: 'Show all',
    showRecommended: 'Show recommended',
    specify: 'Specify',
    month: 'Month',
    day: 'Day',
    dayShort: 'DD',
    year: 'Year',
    yearShort: 'YYYY',
    monthsShort: {
      '01': 'Jan',
      '02': 'Feb',
      '03': 'Mar',
      '04': 'Apr',
      '05': 'May',
      '06': 'Jun',
      '07': 'Jul',
      '08': 'Aug',
      '09': 'Sep',
      '10': 'Oct',
      '11': 'Nov',
      '12': 'Dec'
    },
    newWindow: 'Opens in a new window'
  };
});