define('frontend/pods/components/user/forgotten-password-form/component', ['exports', 'ember-cp-validations', 'ember-rapid-forms/mixins/input-errors', 'frontend/mixins/validation-helper'], function (exports, _emberCpValidations, _inputErrors, _validationHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      validators: [(0, _emberCpValidations.validator)("presence", true)]
    }
  });

  exports.default = Ember.Component.extend({
    institute: Ember.inject.service(),
    messages: Ember.inject.service(),
    baseTextKey: "provider",
    goBackRoute: "provider.login",

    /**
     * @override: ember lifecycle
     */
    didReceiveAttrs: function didReceiveAttrs() {
      for (var _len = arguments.length, params = Array(_len), _key = 0; _key < _len; _key++) {
        params[_key] = arguments[_key];
      }

      this._super(params);
      this.set('model', Ember.Object.extend(Validations, _inputErrors.default, _validationHelper.default, {
        email: null
      }).create(Ember.getOwner(this).ownerInjection()));
    },


    actions: {
      submit: function submit() {
        this._sendEmail();
      }
    },

    _sendEmail: function _sendEmail() {
      var self = this;

      Ember.$.ajax({
        type: 'POST',
        url: "/users/password",
        dataType: 'json',
        data: {
          "user[email]": self.get("model.email"),
          "user[institute_id]": self.get("institute.current.id")
        }
      }).then(function () /*data*/{
        self.set("emailSent", true);
      }, function (jqXHR) {
        jqXHR.then = null; // tame jQuery's ill mannered promises
        self.get("messages").danger("An error occurred");
      });
    }
  });
});