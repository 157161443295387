define("frontend/locales/en/kiosk", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    staffLogin: "Staff Login",
    home: "Home",
    forbidden: {
      title: "Kiosk Mode is Disabled",
      description: "Please contact your administrator or support@mirah.com"
    },
    expired: {
      title: "Content Expired",
      description: "The data you were trying to access has expired. Please go back to the home screen and try again."
    },
    timeout: {
      title: "Your session has timed out",
      description: "Your session has timed out due to a period of inactivity. Please go back to the home screen and try again."
    },
    patient: {
      assessment: {
        existing: {
          title: "There is an assessment for you to complete",
          goToPrevious: "Go to assessment",
          startNew: "Start new assessment"
        },
        exit: {
          redirect: "You are being redirected"
        }
      },
      completeAssessment: {
        title: "Assessment Complete!",
        finishText: "Please press the button the button to return to the home screen",
        submit: "Back to Home"
      },
      createPatient: {
        title: "Please enter your details",
        description: "Please tell us some important details about yourself. These are completely confidential and are used to identify you on subsequent visits so we can track your progress over time.",
        primaryClinician: "Your primary clinician (if known)",
        submit: "Submit"
      },
      idForm: {
        description: "Please enter your {mrn}",
        submit: "Submit"
      },
      emailForm: {
        description: "Please enter your email address",
        submit: "Submit"
      },
      welcome: {
        title: "Welcome to {name}!",
        description: "This is a short assessment to help us understand your treatment needs. Have you taken the assessment before?",
        footer: "If you are uncomfortable with taking this assessment, please contact a member of staff.",
        firstTime: "First Time Patient",
        returning: "Returning Patient"
      },
      firstTime: {
        title: "What is Mirah?",
        description: "Mirah is an assessment tool designed to help you and your provider plan your treatment. You'll take it every week and discuss the results with your provider.",
        beginCreate: "Next"
      },
      returning: {
        title: "Welcome back!",
        description: "Please enter your details to continue",
        footer: "If you have problems signing in, please contact a member of staff"
      },
      providerCheck: {
        title: "Please confirm your provider",
        text: "Are you here to see {provider} today?"
      },
      signInForm: {
        submit: "Continue"
      },
      providerUpdate: {
        title: "Who are you here to see today?",
        provider: "Provider name",
        updatePrimary: "This provider is also my primary provider",
        select: "Continue",
        noProvider: "My provider is not on this list"
      }
    }
  };
});