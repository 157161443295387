define('frontend/locales/en/reporting', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = {
    settingsLabel: "Filters & Settings",
    reportSelector: {
      label: 'Other Reports'
    },
    entitySearch: {
      loadingMessage: 'Loading...',
      placeholder: 'Search units, providers, patients...'
    },
    filtering: {
      timeOptions: {
        presets: 'Presets',
        custom: "Custom Date Range",
        today: "Today",
        yesterday: "Yesterday",
        thisWeek: "Week-to-date",
        thisMonth: "Month-to-date",
        thisYear: "Year-to-date",
        last30Days: "Last 30 Days",
        lastDay: "Prior day",
        lastWeek: "Prior week",
        lastMonth: "Prior month",
        lastYear: "Prior year",
        allTime: "All Time",
        last90Days: "Last 90 Days",
        thisQuarter: "This quarter",
        lastQuarter: "Last quarter",
        priorPeriod: 'Prior Period',
        priorYear: 'Prior Year',
        none: 'None',
        na: 'N/A'
      },
      updateReport: "Update Report",
      dateRange: "Date Range",
      comparisonDateRange: "Comparison Period",
      breakdown: "Breakdown",
      breakdowns: _defineProperty({
        none: 'None',
        age: 'By Age Group'
      }, 'login-method', 'By Login Method'),
      scale: "Scale",
      scales: {
        none: 'Severity',
        phq9: 'PHQ-9',
        gad7: 'GAD-7',
        pcl: 'PCL'
      },
      provider: {
        title: 'Provider',
        all: 'All'
      },
      organization: {
        title: 'Org',
        all: 'All'
      },
      "provider-all": "All Providers"
    },
    columnInfo: 'Report Key',
    status: {
      sorting: 'Sorting data',
      fetching: 'Fetching data'
    },
    sorting: {
      text: 'Sorting'
    },
    limit: {
      currentLimit: 'Showing {limit} results',
      showAll: 'Show All',
      addLimit: 'Limit to {limit} results'
    },
    notApplicable: 'N/A',
    categories: {
      outcomes: 'Outcomes',
      adoption: 'Adoption',
      operations: 'Operations'
    },
    reps: {
      taking_time_summary: {
        title: 'Measurement Time Summary',
        helpDescription: 'Summarizes how long Patients take to complete measurements',

        totalCompleted: {
          title: 'Total Completed',
          helpDescription: 'Number of completed measurements in the selected time period'
        },
        medianTime: {
          title: 'Median Time',
          helpDescription: 'The median time taken to complete a measurement'
        },
        meanTime: {
          title: 'Mean Time',
          helpDescription: 'The mean time taken to complete a measurement'
        },
        lessThanFive: {
          title: '< 5min',
          helpDescription: 'Percent of measures completed in less than 5 minutes'
        },
        fiveToTen: {
          title: '5-10min',
          helpDescription: 'Percent of measures completed in 5-10 minutes'
        },
        tenToFifteen: {
          title: '10-15min',
          helpDescription: 'Percent of measures completed in 10-15 minutes'
        },
        fifteenToTwenty: {
          title: '15-20min',
          helpDescription: 'Percent of measures completed in 15-20 minutes'
        },
        moreThanTwenty: {
          title: '> 20min',
          helpDescription: 'Percent of measures completed in more than 20 minutes'
        }
      },
      user_recent_activity: {
        title: 'Recent User Activity',
        helpDescription: 'Reports the number of times a user viewed the Mirah dashboard, viewed a patient report, or created a patient',

        provider: {
          title: 'Provider'
        },
        providerRole: {
          title: ''
        },
        lastLogin: {
          title: 'Last Login',
          helpDescription: 'The last time this user logged in to the Mirah platform'
        },
        createdAt: {
          title: 'Created On',
          helpDescription: 'Date the user was added to the Mirah platform'
        }
      },
      provider_assessments: {
        title: 'Measures by Provider',
        helpDescription: 'Reports the number of completed measures administered, by provider',

        provider: {
          title: 'Provider'
        },
        organizations: {
          title: 'Units',
          helpDescription: 'List of units this provider belongs to'
        }
      },
      patient_assessments: {
        title: 'Measures by Patient',
        helpDescription: 'Reports the number of completed measures administered, by patient',
        patient: {
          title: 'Patient'
        },
        provider: {
          title: 'Provider'
        },
        startDate: {
          title: 'First Session'
        },
        organizations: {
          title: 'Units',
          helpDescription: 'List of units this patient belongs to'
        }
      },
      reimbursement_summary: {
        title: 'Reimbursement Summary'
      },
      adherence_drilldown: {
        title: 'Adherence Drill‑down',
        helpDescription: 'Report showing adherence at every level.',
        provider: {
          title: 'Provider'
        },
        providerRole: {
          title: ''
        },
        totalPatients: {
          title: 'Total Caseload',
          helpDescription: 'Number of overall patients assigned to this Provider'
        },
        measuredPatients: {
          title: 'Measured Patients',
          helpDescription: 'Number of patients measured in the selected time period'
        },
        repeatPatients: {
          title: 'Repeat Patients',
          helpDescription: 'Number of patients with more than one measurement in the selected time period'
        },
        totalFulfilled: {
          title: 'Sessions Fulfilled',
          helpDescription: 'Number of sessions fulfilled, i.e. opportunities to measure patients'
        },
        totalCanceled: {
          title: 'Measurements Canceled',
          helpDescription: 'Number of canceled measurements in the selected time period'
        },
        totalMissed: {
          title: 'Sessions NOT measured',
          helpDescription: 'Number of missed measurements, for any reason, in the selected time period. A measurement is considered "missed" if the scheduled time has past and it has not been completed or canceled.'
        },
        totalComplete: {
          title: 'Measurements Complete',
          helpDescription: 'Number of completed measurements in the selected time period'
        },
        completePercent: {
          title: '% Completed',
          helpDescription: 'Percentage of planned measurements that were completed, <strong>excluding</strong> canceled measurements: <tt>Total Complete / (Total Planned - Total Canceled)</tt>.'
        },
        canceledPercent: {
          title: '% Canceled',
          helpDescription: 'Percentage of all planned measurements that were canceled: <tt>Total Canceled / Total Planned</tt>.'
        },
        reportViews: {
          title: 'Report Viewing',
          helpDescription: 'Shows: <ul><li>GREEN - what percent of complete reports have been viewed in or before the session</li><li>LILAC - after the session</li><li>WHITE - not at all</li></ul>'
        },
        viewedBeforeSession: {
          title: 'Viewed Before Session',
          helpDescription: 'Number of reports viewed by this provider before the session'
        },
        viewedInSession: {
          title: 'Viewed In Session',
          helpDescription: 'Number of reports viewed by this provider in session'
        },
        viewedByPrimary: {
          title: 'Viewed',
          helpDescription: 'Total number of reports viewed by this provider, at any time'
        },
        notViewedByPrimary: {
          title: 'Unviewed',
          helpDescription: 'Total number of reports not viewed by this provider'
        },
        actions: {
          title: ' '
        },
        graph: {
          title: 'Measures completed'
        }
      },
      adherence_drilldown_v2: {
        title: 'Adherence Drill‑down',
        helpDescription: 'Report showing adherence at every level.',
        provider: {
          title: 'Provider'
        },
        providerRole: {
          title: ''
        },
        totalPatients: {
          title: 'Total Caseload',
          helpDescription: 'Number of overall patients assigned to this Provider'
        },
        measuredPatients: {
          title: 'Measured Patients',
          helpDescription: 'Number of patients measured in the selected time period'
        },
        repeatPatients: {
          title: 'Repeat Patients',
          helpDescription: 'Number of patients with more than one measurement in the selected time period'
        },
        totalFulfilled: {
          title: 'Sessions Fulfilled',
          helpDescription: 'Number of sessions fulfilled, i.e. opportunities to measure patients'
        },
        totalCanceled: {
          title: 'Measurements Canceled',
          helpDescription: 'Number of canceled measurements in the selected time period'
        },
        totalMissed: {
          title: 'Sessions NOT measured',
          helpDescription: 'Number of missed measurements, for any reason, in the selected time period. A measurement is considered "missed" if the scheduled time has past and it has not been completed or canceled.'
        },
        totalComplete: {
          title: 'Measurements Complete',
          helpDescription: 'Number of completed measurements in the selected time period'
        },
        completePercent: {
          title: '% Completed',
          helpDescription: 'Percentage of planned measurements that were completed, <strong>excluding</strong> canceled measurements: <tt>Total Complete / (Total Planned - Total Canceled)</tt>.'
        },
        canceledPercent: {
          title: '% Canceled',
          helpDescription: 'Percentage of all planned measurements that were canceled: <tt>Total Canceled / Total Planned</tt>.'
        },
        reportViews: {
          title: 'Report Viewing',
          helpDescription: 'Shows: <ul><li>GREEN - what percent of complete reports have been viewed in or before the session</li><li>LILAC - after the session</li><li>WHITE - not at all</li></ul>'
        },
        viewedBeforeSession: {
          title: 'Viewed Before Session',
          helpDescription: 'Number of reports viewed by this provider before the session'
        },
        viewedInSession: {
          title: 'Viewed In Session',
          helpDescription: 'Number of reports viewed by this provider in session'
        },
        viewedByPrimary: {
          title: 'Viewed',
          helpDescription: 'Total number of reports viewed by this provider, at any time'
        },
        notViewedByPrimary: {
          title: 'Unviewed',
          helpDescription: 'Total number of reports not viewed by this provider'
        },
        actions: {
          title: ' '
        },
        graph: {
          title: 'Measures completed'
        }
      },
      adherence: {
        title: 'My Adherence',
        helpDescription: 'Report showing your measurement adherence.',
        provider: {
          title: 'Provider'
        },
        providerRole: {
          title: ''
        },
        totalPatients: {
          title: 'Total Caseload',
          helpDescription: 'Number of overall patients assigned to you'
        },
        measuredPatients: {
          title: 'Measured Patients',
          helpDescription: 'Number of patients measured in the selected time period'
        },
        repeatPatients: {
          title: 'Repeat Patients',
          helpDescription: 'Number of patients with more than one measurement in the selected time period'
        },
        totalFulfilled: {
          title: 'Sessions Fulfilled',
          helpDescription: 'Number of sessions fulfilled, i.e. opportunities to measure patients'
        },
        totalCanceled: {
          title: 'Measurements Canceled',
          helpDescription: 'Number of canceled measurements in the selected time period'
        },
        totalMissed: {
          title: 'Sessions NOT measured',
          helpDescription: 'Number of missed measurements, for any reason, in the selected time period. A measurement is considered "missed" if the scheduled time has past and it has not been completed or canceled.'
        },
        totalComplete: {
          title: 'Measurements Complete',
          helpDescription: 'Number of completed measurements in the selected time period'
        },
        completePercent: {
          title: '% Completed',
          helpDescription: 'Percentage of planned measurements that were completed, <strong>excluding</strong> canceled measurements: <tt>Total Complete / (Total Planned - Total Canceled)</tt>.'
        },
        canceledPercent: {
          title: '% Canceled',
          helpDescription: 'Percentage of all planned measurements that were canceled: <tt>Total Canceled / Total Planned</tt>.'
        },
        reportViews: {
          title: 'Report Viewing',
          helpDescription: 'Shows: <ul><li>GREEN - what percent of complete reports have been viewed in or before the session</li><li>LILAC - after the session</li><li>WHITE - not at all</li></ul>'
        },
        viewedBeforeSession: {
          title: 'Viewed Before Session',
          helpDescription: 'Number of reports viewed by this provider before the session'
        },
        viewedInSession: {
          title: 'Viewed In Session',
          helpDescription: 'Number of reports viewed by this provider in session'
        },
        viewedByPrimary: {
          title: 'Viewed',
          helpDescription: 'Total number of reports viewed by this provider, at any time'
        },
        notViewedByPrimary: {
          title: 'Unviewed',
          helpDescription: 'Total number of reports not viewed by this provider'
        },
        actions: {
          title: ' '
        },
        graph: {
          title: 'Measures completed'
        }
      },
      no_show_history_drilldown: {
        title: 'No-show History Drill‑down',
        helpDescription: 'Shows no-show rates for the given period',
        provider: {
          title: 'Provider'
        },
        noShows: {
          title: 'No Shows',
          helpDescription: 'Number of no-shows in the period'
        },
        canceled: {
          title: 'Cancellations',
          helpDescription: 'Number of cancellations in the period'
        },
        booked: {
          title: 'Booked',
          helpDescription: 'Total booked (i.e. non-cancelled) appointments'
        },
        noShowRate: {
          title: 'No-Show Rate'
        },
        actions: {
          title: ''
        }
      },
      session_outcome_drilldown: {
        title: 'Session Outcome Drill‑down',
        helpDescription: 'Explore per-session outcomes on a per-scale basis',
        totalMeasurements: {
          title: 'Total Measurements',
          helpDescription: 'The total number of times this scale has been administered, including times it was taken but left incomplete.'
        },
        validMeasurements: {
          title: 'Valid Measurements',
          helpDescription: 'The total number of times this scale has been administered and is valid.'
        },
        meanScore: {
          title: 'Avg Score',
          helpDescription: 'The average all of administrations scores'
        },
        meanDaysBetweenMeasurement: {
          title: 'Avg Days Between Measurement',
          helpDescription: 'The average days between administration'
        },
        meanDelta: {
          title: 'Avg Δ/session',
          helpDescription: 'The average session-to-session change for this scale'
        },
        meanDeltaPerWeek: {
          title: 'Avg Δ/week',
          helpDescription: 'The average change in the scale per week when under measurement.'
        },
        actions: {
          title: ''
        }
      },
      care_episode_outcome_drilldown: {
        title: 'Care Episode Outcomes Drill‑down',
        helpDescription: 'Explore outcomes for episodes of care on a per-scale basis',
        meanIntakeScore: {
          title: 'Avg Intake Score',
          helpDescription: 'The average score at intake'
        },
        meanCurrentScore: {
          title: 'Avg End Score',
          helpDescription: 'The average score at the end of the period'
        },
        meanNumberOfSessions: {
          title: 'Avg # Sessions',
          helpDescription: 'The average number of sessions'
        },
        meanLengthOfService: {
          title: 'Avg LOS (days)',
          helpDescription: 'The average length of service in days'
        },
        total: {
          title: 'Total Treatments',
          helpDescription: 'Total episodes of care'
        },
        meanDeltaSinceIntake: {
          title: 'Avg Δ from intake',
          helpDescription: 'The average score at intake'
        },
        meaningfulChange: {
          title: '# Meaningful Change',
          helpDescription: 'The number of episodes of care with a meaningful change in scale value'
        },
        thresholdChanged: {
          title: '# Threshold Changed',
          helpDescription: 'The number of episodes of care where the threshold has meaningful changed'
        },
        intakeBreakdown: {
          title: 'Intake Severity Breakdown'
        },
        currentBreakdown: {
          title: 'Current Severity Breakdown'
        },
        actions: {
          title: ''
        }
      },
      provider_load: {
        title: 'Provider Workload'
      },
      session_status_summary: {
        title: 'Session Status Summary'
      },
      session_status_detail: {
        title: 'Session Status Detail',
        patient: {
          title: 'Patient'
        },
        target_date: {
          title: 'Target Date'
        },
        effective_date: {
          title: 'Effective Date'
        },
        adherence_status: {
          title: 'Adherence Status'
        },
        super_status: {
          title: 'Super Status'
        },
        session_status: {
          title: 'Session Status'
        },
        report_status: {
          title: 'Report Status'
        },
        remote_id: {
          title: 'EHR Appt. ID'
        },
        remote_status: {
          title: 'EHR Status'
        }
      },
      conditions: {
        title: 'Diagnoses',
        helpDescription: 'Shows diagnoses active in the time period present',
        institute_condition: {
          title: 'Diagnosis',
          helpDescription: 'Which diagnosis the row refers to'
        },
        active: {
          title: 'Active',
          helpDescription: 'Diagnoses which are still active, i.e. being treated'
        },
        remission: {
          title: 'In Remission',
          helpDescription: 'Diagnoses which are in remission'
        },
        resolved: {
          title: 'Active',
          helpDescription: 'Diagnoses which are resolved'
        },
        total: {
          title: 'Total',
          helpDescription: 'How many total diagnoses of this type were active in the period'
        },
        addressedPercent: {
          title: '% Addressed',
          helpDescription: 'Percentage of diagnoses in remission or resolved'
        },
        actions: {
          title: ' '
        }
      },
      compliance_report: {
        title: 'Compliance',
        helpDescription: 'Shows compliance information for the CTS030.01.09 standard',
        standards: {
          adoption: {
            title: 'CTS.03.01.09.1',
            description: "The organization uses a standardized tool or instrument to monitor the individual’s progress in achieving his or her care, treatment, or service goals. <br/> Note: Ideally, the tool or instrument monitors progress from the individual’s perspective. The tool or instrument may be focused on a population or diagnostic category (such as depression or anxiety), or the tool or instrument may have a more global focus such as general distress, functional status, quality of life (especially in regard to intellectual/developmental disabilities and other physical and/or sensory disabilities), wellbeing, or permanency (especially in regard to foster care)."
          },
          treatment: {
            title: 'CTS.03.01.09.2',
            description: "The organization gathers and analyzes the data generated through standardized monitoring, and the results are used to inform the goals and objectives of the individual’s plan for care, treatment, or services as needed. (See also CTS.03.01.03, EP 4)"
          },
          outcomes: {
            title: 'CTS.03.01.09.3',
            description: "The organization evaluates the outcomes of care, treatment, or services provided to the population(s) it serves by aggregating and analyzing the data gathered through the standardized monitoring effort. (For more information, refer to Standard PI.02.01.01)."
          },
          eatingDisorders: {
            title: 'CTS.03.01.09.4',
            description: "For organizations that provide eating disorders care, treatment, or services: The organization assesses outcomes of care, treatment, or services based on data collected at admission. Examples of such data include complete history and physical including height, weight, frequency of binge eating and purging (when applicable), eating disorder diagnosis, Body Mass Index (BMI), heart rate, date of last period, and other appropriate lab tests (such as potassium, phosphorus, thyroid, hemoglobin, and glucose) as determined by the organization and in accordance with the level of care provided. (See also CTS.02.03.11, EP 1)."
          }
        }
      },
      coverage_drilldown: {
        title: 'Insurance Eligibility Drill‑down (Internal)',
        helpDescription: 'Explore insurance eligibility information.',
        insuredPatients: {
          title: 'Insured Patients',
          helpDescription: 'The number of insured patients'
        },
        uninsuredPatients: {
          title: 'Uninsured Patients',
          helpDescription: 'The number of patients without insurance'
        },
        percentInsured: {
          title: '% Insured',
          helpDescription: 'The percentage of patients with insurance'
        },
        actions: {
          title: ''
        }
      },
      enrollment_drilldown: {
        title: 'Enrollment Drill‑down',
        helpDescription: 'See number of patients enrolled and under measurement.',
        totalPatients: {
          title: 'Total Caseload',
          helpDescription: 'The number of patients undergoing treatment'
        },
        enrolledPatients: {
          title: 'Enrolled',
          helpDescription: 'The number of patients enrolled for measurement who will automatically receive assessments.'
        },
        scheduledPatients: {
          title: 'Scheduled',
          helpDescription: 'The number of patients who have had at least one measurement completed or have at least one scheduled'
        },
        measuredPatients: {
          title: 'Measured',
          helpDescription: 'The number of patients who have had at least one measurement completed.'
        },
        futureScheduledPatients: {
          title: 'Future Scheduled',
          helpDescription: 'The number of patients who have scheduled but uncompleted appointments'
        },
        enrolledPercentage: {
          title: '% Enrolled',
          helpDescription: 'The percentage of patients who are enrolled for measurement, whether or not they have any measurements planned or completed'
        },
        enrollment: {
          title: 'Enrollment',
          helpDescription: 'A breakdown of whether the patients are enrolled, and whether they have been measured or have measurements in the future'
        },
        unenrolledPatients: {
          title: 'Unenrolled',
          helpDescription: 'The number of patients who are not enrolled for measurement'
        },
        unscheduledPatients: {
          title: 'Unscheduled',
          helpDescription: 'The number of patients who are enrolled but have no measurements completed or coming up.'
        },
        actions: {
          title: ''
        }
      },
      patient_usage_drilldown: {
        title: 'Patient Usage Drill‑down',
        helpDescription: 'See number of patients measured in a given period.',
        measuredPatients: {
          title: 'Measured Patients',
          helpDescription: 'The number of patients successfully measured'
        },
        attemptedPatients: {
          title: 'Attempted Patients',
          helpDescription: 'The number of patients we attempted to measure, including those who never took a measurement'
        },
        actions: {
          title: ''
        }
      },
      charge_efficiency_drilldown: {
        title: 'Reimbursement Efficiency Drill‑down',
        helpDescription: 'Explore reimbursement efficiency information. This helps you understand how adherence (how often patients complete reports) affects the amount of reimbursement you can claim. It will also show you the maximum contracted amount you could have received if you had selected a measurement plan that was optimized for the contract.',
        actions: {
          title: ''
        },
        expectedSessions: {
          title: 'Sessions Reimbursed',
          helpDescription: 'Number of sessions that have met the criteria for reimbursement.'
        },
        expectedUnits: {
          title: 'Reimbursed Units',
          helpDescription: 'Number of units that have met the criteria for reimbursement'
        },
        expectedAmount: {
          title: 'Reimbursed $',
          helpDescription: 'Total amount of reimbursement expected for assessments that met the criteria'
        },
        plannedSessions: {
          title: 'Sessions Planned',
          helpDescription: 'Number of sessions that would be reimbursed if all assessments were completed.'
        },
        plannedUnits: {
          title: 'Planned Units',
          helpDescription: 'Number of units that would be reimbursed if all assessments were completed.'
        },
        plannedAmount: {
          title: 'Planned $',
          helpDescription: 'Total amount of reimbursement that would be paid if all assessments were completed'
        },
        maxUnits: {
          title: 'Max Units',
          helpDescription: 'Number of units that would be reimbursed if a measurement plan optimized for the contract was used with 100% adherence.'
        },
        maxAmount: {
          title: 'Max $',
          helpDescription: 'Total amount of reimbursement that could have been gathered if a measurement plan optimized for the contract was used with 100% adherence'
        },
        adherenceEfficiencyUnitsPercent: {
          title: 'Unit Adherence Efficiency',
          helpDescription: 'Percentage of units specified as part of your measurement plans that were measured and billable.'
        },
        adherenceEfficiencyAmountPercent: {
          title: '$ Adherence Efficiency',
          helpDescription: 'Percentage of total price specified as part of your measurement plans that were measured and billable.'
        },
        contractEfficiencyUnitsPercent: {
          title: 'Unit Contract Efficiency',
          helpDescription: 'Percentage of units that would have been billed under an optimal measurement plan that were actually billed.'
        },
        contractEfficiencyAmountPercent: {
          title: '$ Contract Efficiency',
          helpDescription: 'Percentage amount of money that would have been billed under an optimal measurement plan that were actually billed.'
        }
      },
      billable_charge_drilldown: {
        title: 'Billable Reimbursement Drill‑down',
        helpDescription: 'Explore reimbursement information for reimbursements that are billable.',
        actions: {
          title: ''
        },
        receivedSessions: {
          title: 'Sessions Reimbursed',
          helpDescription: 'Sessions which have been submitted for reimbursement and paid'
        },
        receivedUnits: {
          title: 'Units Reimbursed',
          helpDescription: 'Units which have been submitted for reimbursement and paid'
        },
        receivedAmount: {
          title: '$ Reimbursed',
          helpDescription: 'Amount which has been submitted for reimbursement and paid'
        },
        receivedAmountCumulative: {
          title: 'Total $ Reimbursed',
          helpDescription: 'Total $ amount which have been submitted for reimbursement and paid'
        },
        billedSessions: {
          title: 'Sessions Billed',
          helpDescription: 'Sessions which have been submitted for reimbursement but not yet paid'
        },
        billedUnits: {
          title: 'Units Billed',
          helpDescription: 'Units which have been submitted for reimbursement but not yet paid'
        },
        billedAmount: {
          title: '$ Billed',
          helpDescription: 'Amount which has been submitted for reimbursement but not yet paid. Note that this amount is based on the list price and is not likely to be the actual amount received - see "$ Expected".'
        },
        billedAmountCumulative: {
          title: 'Total $ Billed',
          helpDescription: 'Cumulative total $ amount which have been submitted for reimbursement but not yet paid. Note that this amount is based on the list price and is not likely to be the actual amount received - see "Total $ Expected"'
        },
        expectedUnits: {
          title: 'Units Expected',
          helpDescription: 'Number of units we expect to be reimbursed according to our algorithmic predictions.'
        },
        expectedAmount: {
          title: '$ Expected',
          helpDescription: 'Amount which has been submitted for reimbursement but not yet paid. Note that this amount is based on the list price and is not likely to be the actual amount received - see "$ Expected".'
        },
        expectedAmountCumulative: {
          title: 'Expected Total $',
          helpDescription: 'Cumulative total of the amount we expect to be reimbursed by the payors.'
        },
        billableSessions: {
          title: 'Sessions Billable',
          helpDescription: 'Sessions which can be billed, or have already been billed or paid'
        },
        billableUnits: {
          title: 'Units Billed',
          helpDescription: 'Units which can be billed, or have already been billed or paid'
        },
        billableAmount: {
          title: '$ Billed',
          helpDescription: 'Amount which can be billed, or has already been billed or paid. Note that this amount is based on the list price and is not likely to be the actual amount received - see "$ Expected".'
        },
        billableAmountCumulative: {
          title: 'Total $ Billable',
          helpDescription: 'Cumulative total $ amount which can be billed, or hs already been billed or paid. Note that this amount is based on the list price and is not likely to be the actual amount received - see "Total $ Expected"'
        }
      },
      taking_time_drilldown: {
        title: 'Taking Time Drill‑down',
        helpDescription: 'Explore how long reports take to complete.',
        actions: {
          title: ''
        },
        totalCompleted: {
          title: 'Total Completed',
          helpDescription: 'Number of completed assessments in the selected time period. Note that this may have multiple completed assessments per session if multiple respondents were involved'
        },
        meanTime: {
          title: 'Mean Time (m)',
          helpDescription: 'The mean time taken to complete a measurement'
        },
        lessThanFive: {
          title: '< 5min',
          helpDescription: 'Percent of measures completed in less than 5 minutes'
        },
        fiveToFifteen: {
          title: '5-15min',
          helpDescription: 'Percent of measures completed in 5-15 minutes'
        },
        fifteenToTwenty: {
          title: '15-20min',
          helpDescription: 'Percent of measures completed in 15-20 minutes'
        },
        moreThanTwenty: {
          title: '> 20min',
          helpDescription: 'Percent of measures completed in more than 20 minutes'
        }
      },
      experiment_enrollment_drilldown: {
        title: 'Experiment Enrollment Drill‑down',
        helpDescription: 'Explore how many participants each experiment has, and where they are active..',
        actions: {
          title: ''
        },
        optOuts: {
          title: 'Opt-outs',
          helpDescription: 'The number of patients who opted out of the experiment.'
        },
        pending: {
          title: 'Pending consent',
          helpDescription: 'The number of patients who are still pending consent.'
        },
        enrolled: {
          title: 'Enrolled',
          helpDescription: 'The number of patients who consented into the experiment and are considered active.'
        },
        enrollmentRate: {
          title: 'Enrollment Rate',
          helpDescription: 'The percentage of asked participants who elected to enroll, or were automatically enrolled.'
        }
      },
      client_assessment_completion_drilldown: {
        title: 'Patient Assessment Completion Drill‑down',
        helpDescription: 'Explore which patients and related people are filling in assessments and how far through completion they are. This applies to only complete and expired assessments. Assessments which are still in process (i.e. are still active but have not been completed yet) are not included in this report.',
        actions: {
          title: ''
        },
        completion: {
          title: 'Completion',
          helpDescription: 'Whether the assessment was not started, partially completed, or completed.'
        },
        totalAssessments: {
          title: 'Total',
          helpDescription: 'The total number of assessments that were created, are no longer active, and have not been canceled.'
        },
        expiredAssessments: {
          title: '# Expired',
          helpDescription: 'The number of assessments that were created but never started, and have since expired'
        },
        partialAssessments: {
          title: '# Partially completed',
          helpDescription: 'The number of assessments that were started but never fully completed. We define 80% completion as completed.'
        },
        completedAssessments: {
          title: '# Completed',
          helpDescription: 'The number of assesssments that had at least 80% of items completed'
        },
        percentageStarted: {
          title: '% Started',
          helpDescription: 'The percentage of assessments started by the respondent.'
        },
        percentageComplete: {
          title: '% Complete',
          helpDescription: 'The percentage of assessments that had at least 80% of items responded to.'
        },
        percentageItemsResponded: {
          title: '% Items Responded',
          helpDescription: 'The percentage of items responded to for assessments that were started'
        }
      },
      provider_assessment_completion_drilldown: {
        title: 'Provider Assessment Completion Drill‑down',
        helpDescription: 'Explore which providers filling in assessments and how far through completion they are. This applies to only complete and expired assessments. Assessments which are still in process (i.e. are still active but have not been completed yet) are not included in this report.',
        actions: {
          title: ''
        },
        completion: {
          title: 'Completion',
          helpDescription: 'Whether the assessment was not started, partially completed, or completed.'
        },
        totalAssessments: {
          title: 'Total',
          helpDescription: 'The total number of assessments that were created, are no longer active, and have not been canceled.'
        },
        expiredAssessments: {
          title: '# Expired',
          helpDescription: 'The number of assessments that were created but never started, and have since expired'
        },
        partialAssessments: {
          title: '# Partially completed',
          helpDescription: 'The number of assessments that were started but never fully completed. We define 80% completion as completed.'
        },
        completedAssessments: {
          title: '# Completed',
          helpDescription: 'The number of assesssments that had at least 80% of items completed'
        },
        percentageStarted: {
          title: '% Started',
          helpDescription: 'The percentage of assessments started by the respondent.'
        },
        percentageComplete: {
          title: '% Complete',
          helpDescription: 'The percentage of assessments that had at least 80% of items responded to.'
        },
        percentageItemsResponded: {
          title: '% Items Responded',
          helpDescription: 'The percentage of items responded to for assessments that were started'
        }
      },
      genoa_metrics_drilldown: {
        title: 'Genoa Contract Metrics Drill‑down',
        helpDescription: 'Tracks metrics specific to the Genoa pilot',
        actions: {
          title: ''
        },
        patientsAttempted: {
          title: '# Patients Attempted',
          helpDescription: 'Number of patients where measurement was attempted.'
        },
        patientsMeasured: {
          title: '# Patients Measured',
          helpDescription: 'Number of patients who were successfully measured at least once'
        },
        percentageMeasured: {
          title: '% Patients Measured',
          helpDescription: 'Percentage of patients who were successfully measured at least once'
        },
        patientIntakesAttempted: {
          title: '# Intakes',
          helpDescription: 'Number of patients who had intakes in this period, i.e. first or second sessions.'
        },
        patientIntakesMeasured: {
          title: '# Intakes Measured',
          helpDescription: 'Number of patients who were successfully measured at least once during their first or second sessions'
        },
        percentageIntakesMeasured: {
          title: '% Intakes Measured',
          helpDescription: 'Percentage of intakes that were successfully measured'
        },
        totalAssessments: {
          title: '# Assessments',
          helpDescription: 'Total number of assessments created'
        },
        completedAssessments: {
          title: '# Completed Assessments',
          helpDescription: 'Number of completed assessments'
        },
        percentageAssessmentsCompleted: {
          title: '% Assessments Completed',
          helpDescription: 'Percentage of assessments that were completed'
        }
      },
      patient_adoption_flow_drilldown: {
        title: 'Patient Adoption Flows  (Internal)',
        helpDescription: 'Internal patient adoption funnel',
        actions: {
          title: ''
        },
        bothStartedMeasures: {
          title: 'Started Measures (Both)',
          helpDescription: 'Number of patients who started their first measure with both an email and a phone number'
        },
        bothCompletedMeasures: {
          title: 'Completed Measures (Both)',
          helpDescription: 'Number of patients who completed their first measure with both an email and a phone number'
        },
        bothTotalMeasures: {
          title: 'Total Measures (Both)',
          helpDescription: 'Number of patients who received their first measure to complete with both an email and a phone number.'
        },
        emailStartedMeasures: {
          title: 'Started Measures (Email)',
          helpDescription: 'Number of patients who started their first measure with an email only.'
        },
        emailCompletedMeasures: {
          title: 'Completed Measures (Email)',
          helpDescription: 'Number of patients who completed their first measure with an email only.'
        },
        emailTotalMeasures: {
          title: 'Total Measures (Email)',
          helpDescription: 'Number of patients who received their first measure to complete with an email only.'
        },
        phoneStartedMeasures: {
          title: 'Started Measures (Phone)',
          helpDescription: 'Number of patients who started their first measure with a phone number only.'
        },
        phoneCompletedMeasures: {
          title: 'Completed Measures (Phone)',
          helpDescription: 'Number of patients who completed their first measure with a phone number only.'
        },
        phoneTotalMeasures: {
          title: 'Total Measures (Phone)',
          helpDescription: 'Number of patients who received their first measure to complete with a phone number only.'
        },
        noneStartedMeasures: {
          title: 'Started Measures (None)',
          helpDescription: 'Number of patients who started their first measure with no contact information.'
        },
        noneCompletedMeasures: {
          title: 'Completed Measures (None)',
          helpDescription: 'Number of patients who completed their first measure with no contact information.'
        },
        noneTotalMeasures: {
          title: 'Total Measures (None)',
          helpDescription: 'Number of patients who received their first measure to complete with no contact information.'
        }
      },
      treatment_track_enrollment_drilldown: {
        title: 'Treatment Track Enrollment',
        helpDescription: 'Shows the number of enrollments for treatment tracks and stats about those enrollments',
        actions: {
          title: ''
        },
        totalEnrollments: {
          title: '# Enrollments',
          helpDescription: 'Number of enrollments into a treatment track'
        },
        totalActive: {
          title: '# Active',
          helpDescription: 'Number of enrollments which are still active now'
        },
        percentActive: {
          title: 'Active %',
          helpDescription: 'The percentage of enrollments which are still active now of those enrolled in the date period given.'
        },
        meanTreatmentLength: {
          title: 'Avg Tx Length (days)',
          helpDescription: 'The average number of days the treatment track was active. Tracks that are still active will count to today.'
        }
      },
      treatment_track_active_drilldown: {
        title: 'Active Treatment Tracks',
        helpDescription: 'Shows the number active treatment tracks in the period',
        actions: {
          title: ''
        },
        totalActive: {
          title: '# Active',
          helpDescription: 'Number of active treatment track enrollments in the period'
        },
        meanTreatmentLength: {
          title: 'Avg Tx Length (days)',
          helpDescription: 'The average number of days the treatment track was active. Tracks that are still active will count to today.'
        }
      },
      provider_usage_drilldown: {
        title: 'Provider Usage Drill‑down (Internal)',
        helpDescription: 'Internal provider usage drilldown. Providers who are in an active state should be regularly logging in. This drilldown will show which active providers have logged in during the time period specified.',
        actions: {
          title: ''
        },
        activeProviders: {
          title: 'Active Providers',
          helpDescription: 'Providers who have taken action in the system during the specified period'
        },
        totalProviders: {
          title: 'Total Providers',
          helpDescription: 'All providers who were active and expected to take action during the period'
        },
        activePercent: {
          title: 'Active %',
          helpDescription: 'The percentage of providers who were active as a percentage of those who were expected to be'
        }

      },
      optum_adoption_all_drilldown: {
        title: 'Optum Adoption Metric (#1) - All Patients',
        helpDescription: 'Metric #1 for Optum concerns levels of first time adoption.',
        actions: {
          title: ''
        },
        adopted: {
          title: '# Adopted',
          helpDescription: 'Number of patients who have completed at least one assessment after at least 2 attempts to measure them.'
        },
        total: {
          title: '# Total',
          helpDescription: 'Total number of patients who have had at least 2 attempts to measure.'
        },
        percentageAdopted: {
          title: '% Adopted',
          helpDescription: 'Percent of eligible patients who have adopted.'
        }
      },
      optum_adoption_gina_drilldown: {
        title: 'Optum Adoption Metric (#1) - Optum Consented Patients',
        helpDescription: 'Metric #1 for Optum concerns levels of first time adoption. Only counts eligible Optum-consented patients and is the actual result that will be used',
        actions: {
          title: ''
        },
        adopted: {
          title: '# Adopted',
          helpDescription: 'Number of patients who have completed at least one assessment after at least 2 attempts to measure them.'
        },
        total: {
          title: '# Total',
          helpDescription: 'Total number of patients who have had at least 2 attempts to measure.'
        },
        percentageAdopted: {
          title: '% Adopted',
          helpDescription: 'Percent of eligible patients who have adopted.'
        }
      },
      optum_ongoing_all_drilldown: {
        title: 'Optum Ongoing Measurement Metric (#2) - All Patients',
        helpDescription: 'Metric #2 for Optum concerns ongoing level of feedback completion',
        actions: {
          title: ''
        },
        attemptedMeasurements: {
          title: '# Attempted',
          helpDescription: 'Number of attempted measurements'
        },
        completedMeasurements: {
          title: '# Completed',
          helpDescription: 'Number of completed measurements'
        },
        percentageMeasured: {
          title: '% Measured',
          helpDescription: 'Percentage of eligible sessions measured'
        }
      },
      optum_ongoing_gina_drilldown: {
        title: 'Optum Ongoing Measurement Metric (#2) - Optum Consented Patients',
        helpDescription: 'Metric #2 for Optum concerns ongoing level of feedback completion. Only counts eligible Optum-consented patients and is the actual result that will be used',
        actions: {
          title: ''
        },
        attemptedMeasurements: {
          title: '# Attempted',
          helpDescription: 'Number of attempted measurements'
        },
        completedMeasurements: {
          title: '# Completed',
          helpDescription: 'Number of completed measurements'
        },
        percentageMeasured: {
          title: '% Measured',
          helpDescription: 'Percentage of eligible sessions measured'
        }
      },
      optum_feedback_viewing_all_drilldown: {
        title: 'Optum Feedback Viewing Metric (#3) - All Patients',
        helpDescription: 'Metric #3 for Optum concerns feedback viewing rates. Providers must view data when patients complete assessments',
        actions: {
          title: ''
        },
        total: {
          title: '# Feedback Reports',
          helpDescription: 'Number of feedback reports'
        },
        viewed: {
          title: '# Viewed',
          helpDescription: 'Number of viewed feedback reports'
        },
        percentageViewed: {
          title: '% Viewed',
          helpDescription: 'Percentage of eligible feedback reports viewed'
        }
      },
      optum_feedback_viewing_gina_drilldown: {
        title: 'Optum Feedback Viewing Metric (#3) - Optum Consented Patients',
        helpDescription: 'Metric #3 for Optum concerns feedback viewing rates. Providers must view data when patients complete assessments. Only counts eligible Optum-consented patients and is the actual result that will be used',
        actions: {
          title: ''
        },
        total: {
          title: '# Feedback Reports',
          helpDescription: 'Number of feedback reports'
        },
        viewed: {
          title: '# Viewed',
          helpDescription: 'Number of viewed feedback reports'
        },
        percentageViewed: {
          title: '% Viewed',
          helpDescription: 'Percentage of eligible feedback reports viewed'
        }
      },
      optum_feedback_use_all_drilldown: {
        title: 'Optum Feedback Use Metric (#4) - All Patients',
        helpDescription: 'Metric #4 for Optum concerns feedback use in session. Patients will be asked if providers reviewed the results from previous sessions with them',
        actions: {
          title: ''
        },
        sessionsWithResponse: {
          title: '# Patient Responses',
          helpDescription: 'Number of patient responses about whether feedback was addressed'
        },
        sessionsAddressed: {
          title: '# Times Feedback Addressed',
          helpDescription: 'Number of times the patient answered that feedback had been addressed in session'
        },
        percentageAddressed: {
          title: '% Addressed',
          helpDescription: 'Percentage of the number of times the patient indicated that feedback had been addressed`'
        }
      },
      optum_feedback_use_gina_drilldown: {
        title: 'Optum Feedback Use Metric (#4) - Optum Consented Patients',
        helpDescription: 'Metric #4 for Optum concerns feedback use in session. Patients will be asked if providers reviewed the results from previous sessions with them.  Only counts eligible Optum-consented patients and is the actual result that will be used',
        actions: {
          title: ''
        },
        sessionsWithResponse: {
          title: '# Patient Responses',
          helpDescription: 'Number of patient responses about whether feedback was addressed'
        },
        sessionsAddressed: {
          title: '# Times Feedback Addressed',
          helpDescription: 'Number of times the patient answered that feedback had been addressed in session'
        },
        percentageAddressed: {
          title: '% Addressed',
          helpDescription: 'Percentage of the number of times the patient indicated that feedback had been addressed`'
        }
      },
      quality_issue_workflow_drilldown: {
        title: 'Patient Quality Issue Resolution',
        helpDescription: 'Look at average time to resolve quality issues, and number of outstanding quality issues.',
        actions: {
          title: ''
        },
        issuesClosed: {
          title: '# Issues Closed',
          helpDescription: 'Number of issues that were resolved or marked as closed and require no further action'
        },
        issuesResolved: {
          title: '# Issues Resolved',
          helpDescription: 'Number of issues that were resolved and fixed'
        },
        issuesPending: {
          title: '# Issues Pending',
          helpDescription: 'Number of issues that are either snoozed or awaiting a fix in the EHR'
        },
        issuesIgnored: {
          title: '# Issues Ignored',
          helpDescription: 'Number of issues that were marked as ignored'
        },
        issuesUnresolved: {
          title: '# Issues Unresolved',
          helpDescription: 'Number of issues outstanding that require addressing'
        },
        meanIssueResolutionDays: {
          title: 'Avg # days to resolution',
          helpDescription: 'Average number of days for an issue to be resolved'
        },
        meanIssueActiveDays: {
          title: 'Avg # days active',
          helpDescription: 'Average number of days active issues have been open for'
        },
        resolution: {
          title: 'Ticket Resolution',
          helpDescription: 'Whether tickets are closed, pending or still open'
        }
      },
      patient_quality_issue_drilldown: {
        title: 'Data Record Quality & Health',
        helpDescription: 'Look at data quality issues and health to examine where data may be wrong. All statistics are calculated on the basis of the last day of the period.',
        actions: {
          title: ''
        },
        totalPatients: {
          title: 'Total # Records',
          helpDescription: 'Total number of active patient records'
        },
        healthyPatients: {
          title: '# Healthy Records',
          helpDescription: 'Number of patients with no data quality issues'
        },
        warningPatients: {
          title: '# Records with Warnings',
          helpDescription: 'Number of patients with at least one warning but no errors'
        },
        errorPatients: {
          title: '# Records with Errors',
          helpDescription: 'Number of patients with at least one warning but no errors'
        },
        percentHealthy: {
          title: '% Healthy',
          helpDescription: 'Percentage of records which are healthy'
        },
        health: {
          title: 'Record Health',
          helpDescription: 'How many data records have errors, warnings, or are healthy.'
        }
      },
      decision_support_workflow_drilldown: {
        title: 'Decision Support Workflows Drill‑down',
        helpDescription: 'Look at how many decision support algorithms have been triggered and how long they took to resolve',
        actions: {
          title: ''
        },
        totalWorkflows: {
          title: 'Total # Workflows',
          helpDescription: 'Total number of workflows triggered by algorithm'
        },
        activeWorkflows: {
          title: '# Active Workflows',
          helpDescription: 'Number of workflows currently active'
        },
        completeWorkflows: {
          title: '# Complete Workflows',
          helpDescription: 'Number of workflows marked as complete, and requiring no further action'
        },
        canceledWorkflows: {
          title: '# Canceled Workflows',
          helpDescription: 'Number of workflows canceled because the algorithm no longer triggers, and where no action is required'
        },
        snoozedWorkflows: {
          title: '# Snoozed Workflows',
          helpDescription: 'Number of workflows that have been snoozed and will trigger again after their snooze expires'
        },
        status: {
          title: 'Workflow Status',
          helpDescription: 'The status of the workflows'
        },
        meanResolutionDays: {
          title: 'Avg # days to resolution',
          helpDescription: 'Average number of days for an workflow to be resolved'
        },
        meanActiveDays: {
          title: 'Avg # days active',
          helpDescription: 'Average number of days active workflows have been open for'
        }
      },
      decision_support_actions_drilldown: {
        title: 'Decision Support Actions Drill‑down',
        helpDescription: 'Look at how many decision support algorithms actions have been taken',
        actions: {
          title: ''
        },
        totalWorkflows: {
          title: 'Total # Workflows',
          helpDescription: 'Total number of workflows triggered by algorithm'
        },
        workflowsWithAction: {
          title: '# Workflows with Action',
          helpDescription: 'Number of workflows where an action was taken'
        },
        percentageWorkflowsWithAction: {
          title: '% Workflows with action',
          helpDescription: 'Percentage of workflows where an action was taken'
        }
      }
    }
  };
});