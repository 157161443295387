define('frontend/models/provider', ['exports', 'frontend/models/participant', 'ember-data', 'ember-cp-validations', 'frontend/mixins/validation-helper', 'frontend/models/entity'], function (exports, _participant, _emberData, _emberCpValidations, _validationHelper, _entity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Validations = exports.Relationships = undefined;


  var Relationships = Ember.A(['psychologist', 'psychiatrist', 'clinician', 'therapist', 'social_worker', 'administrator', 'director', 'research_assistant', 'front_desk', 'case_manager', 'executive', 'care_manager', 'primary_care_physician', 'supervisor']);

  exports.Relationships = Relationships;


  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: (0, _emberCpValidations.validator)('presence', true),
    lastName: (0, _emberCpValidations.validator)('presence', true),
    email: (0, _emberCpValidations.validator)('format', { allowBlank: false, type: 'email' }),
    phoneNumber: (0, _emberCpValidations.validator)('international-phone-number', { allowBlank: true }),
    defaultRelationship: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.Validations = Validations;
  exports.default = _participant.default.extend(Validations, _validationHelper.default, {
    institute: Ember.inject.service(),

    name: _emberData.default.attr("string", { readOnly: true }),
    status: _emberData.default.attr("string", { readOnly: true }),
    patients: _emberData.default.hasMany('patients', { async: true }),
    user: _emberData.default.belongsTo('user', { inverse: 'provider' }),
    organizations: _emberData.default.hasMany("organization"),
    providerOrganizations: _emberData.default.hasMany("providerOrganization"),
    organizationSubtrees: _emberData.default.hasMany("organization", { inverse: null }),
    entityType: 'provider',
    providerNotificationsTotalCount: _emberData.default.attr("number", { readOnly: true }),

    // Used only for hackily allowing us to have provider surveys without proper infrastructure
    providerSurveyPatient: _emberData.default.belongsTo('patient', { inverse: null }),

    headlineSession: _emberData.default.belongsTo("patientSession", { inverse: null }),

    defaultOrganization: Ember.computed.alias("organizations.firstObject"),
    defaultProviderOrganization: Ember.computed.alias("providerOrganizations.firstObject"),

    // Proxied from user
    title: _emberData.default.attr("string"),
    firstName: _emberData.default.attr("string"),
    lastName: _emberData.default.attr("string"),
    orderedName: _emberData.default.attr("string", { readOnly: true }),
    suffix: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    username: _emberData.default.attr("string"),
    ssoUsername: _emberData.default.attr("string"),
    phoneNumber: _emberData.default.attr("string"),
    notificationPreference: _emberData.default.attr('string'),
    statusEvent: _emberData.default.attr('string', { allowNull: true }),
    npiNumber: _emberData.default.attr("string"),

    identifiers: _emberData.default.attr(),

    hasEmrLink: Ember.computed('identifiers', function () {
      return this.getWithDefault('identifiers.length', 0) > 0;
    }),

    practicing: _emberData.default.attr("boolean"),
    participantType: "provider",
    relationship: _emberData.default.attr("string"),
    defaultRelationship: _emberData.default.attr("string", { defaultValue: "clinician" }),
    accessFrequency: _emberData.default.attr('string', { defaultValue: 'frequent' }),

    // NOTE: there is a nestedData property which is serialized to allow us to save the roles in one call
    saveNested: _emberData.default.attr("boolean"),

    rootPath: Ember.computed('id', function () {
      return 'provider/' + this.get('id');
    }),
    toEntity: function toEntity() {
      return (0, _entity.fromModel)(this, this.get('store'));
    }
  });
});